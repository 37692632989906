<template>
    <div>
        <h1 class="header ">{{ $t("faqTab.watching.headers.hardware") }}</h1>
        <div v-for="(item, index) in array" :key="index" class="mb-5">
            <h1 class="white-title mb-4">{{ $t(item.text) }}</h1>
            <img class="hardware-img" :src="item.img" :alt="$t(item.text)" width="750">
        </div>

        <NoteComponent>
            <p>{{ $t("faqTab.watching.hardware.weDoNotRecommend") }}</p>
            <p>{{ $t("faqTab.watching.hardware.forBest") }}</p>
        </NoteComponent>
    </div>
</template>

<script>
export default {
    components: {
        NoteComponent: () => import("@/components/NoteComponent.vue")
    },
    data() {
        return {
            array: [
                {
                    text: "faqTab.watching.hardware.fireStick",
                    img: require(`../components/assets/devices/firestick.png`)
                },
                {
                    text: "faqTab.watching.hardware.miBox",
                    img: require(`../components/assets/devices/mibox.png`)
                },
                {
                    text: "faqTab.watching.hardware.nvidia",
                    img: require(`../components/assets/devices/nvidia.png`)
                },
                {
                    text: "faqTab.watching.hardware.chromecast",
                    img: require(`../components/assets/devices/chromecast.png`)
                },
                {
                    text: "faqTab.watching.hardware.walmart",
                    img: require(`../components/assets/devices/walmart.png`)
                }
            ]
        }
    }
};
</script>

<style scoped>
h1,
p,
button,
input,
label,
span,
li {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.white-title {
    font-size: 15px;
    font-weight: 600;
}

@media only screen and (max-width: 870px) {
    .hardware-img {
        width: 100%;
    }
}
</style>
