<template>
    <div>
        <h1 class="header">{{ $t("faqTab.gettingStarted.headers.provide") }}</h1>
        <NoteComponent>
            <p>{{ $t("faqTab.gettingStarted.provide.weDont") }}</p>
        </NoteComponent>
        <p class="gray-text mt-4">{{ $t("faqTab.gettingStarted.provide.betterSolution") }}</p>
        <ul>
            <li v-for="item in list" :key="item" class="gray-text">
                {{ item }}
            </li>
        </ul>
        <p class="gray-text">{{ $t("faqTab.gettingStarted.provide.reason") }}</p>
    </div>
</template>

<script>
export default {
    components: {
        NoteComponent: () => import("@/components/NoteComponent.vue")
    },
    data() {
        const list = this.$t("faqTab.gettingStarted.provide.betterSolutionList").split(";")
        return {
            list
        }
    }
};
</script>

<style scoped>
h1,
p,
button,
input,
label,
span,
li {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.gray-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}
</style>
