<template>
    <div>
        <h1 class="header">{{ $t("faqTab.watching.headers.devicesAmount") }}</h1>
        <p class="white-title mb-2">{{ $t("faqTab.watching.devicesAmount.youCanWatch") }}</p>
        <p class="gray-text mb-2">{{ $t("faqTab.watching.devicesAmount.example") }}</p>
        <p class="gray-text mb-2">{{ $t("faqTab.watching.devicesAmount.europe") }}</p>
        <p class="gray-text mb-2">{{ $t("faqTab.watching.devicesAmount.canNotUse") }}</p>
        <p class="gray-text mb-2 border-padding">{{ $t("faqTab.watching.devicesAmount.turnsOff") }}</p>
        <p class="white-title mb-2">{{ $t("faqTab.watching.devicesAmount.inUSAYouCanWatch") }}</p>
        <p class="gray-text mb-2">{{ $t("faqTab.watching.devicesAmount.exampleUSA") }}</p>
        <p class="gray-text mb-2">{{ $t("faqTab.watching.devicesAmount.livesInUSA") }}</p>
        <p class="gray-text mb-5">{{ $t("faqTab.watching.devicesAmount.sameTime") }}</p>
        <p class="gray-text mb-2">{{ $t("faqTab.watching.devicesAmount.example4Tvs") }}</p>
        <p class="gray-text mb-6">{{ $t("faqTab.watching.devicesAmount.allowed") }}</p>
        <DropDown v-for="item in questionsArray" :key="item.id" :question="$t(item.question)"
            :content="item.content">
        </DropDown>
    </div>
</template>

<script>
import Answer11 from '../components/Answers/Answer11.vue';
import Answer12 from '../components/Answers/Answer12.vue';
import Answer13 from '../components/Answers/Answer13.vue';

export default {
    components: {
        DropDown: () => import('../components/DropDown.vue')
    },
    data() {
        return {
            speedLink: "http://speed.apg.ooo/",
            questionsArray: [
                {
                    id: "1",
                    question: "faqTab.watching.devicesAmount.howDoiRemove",
                    content: Answer11
                },
                {
                    id: "2",
                    question: "faqTab.watching.devicesAmount.lostOrStolen",
                    content: Answer12
                },
                {
                    id: "3",
                    question: "faqTab.watching.devicesAmount.addNewDevice",
                    content: Answer13
                }
            ]
        }
    },
    methods: {
        copyToClipboard(value) {
            navigator.clipboard.writeText(value)
        },
    },
};
</script>

<style scoped>
h1,
p,
button,
input,
label,
span,
li {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.white-title {
    font-size: 15px;
    font-weight: 600;
}
.border-padding {
    padding-bottom: 16px;
    border-bottom: 1px solid #FFFFFF14;
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.gray-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}

.copy-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF1F;
    border-radius: 8px;
    border: 1px solid #FFFFFF1A;
}

.link-text {
    color: #918FFA;
}

.copy-icon {
    padding: 5px 8px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.12);
    cursor: pointer;
}
</style>
