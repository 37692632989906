<template>
    <div>
        <h1 class="header">{{ $t("faqTab.troubleshooting.headers.error401") }}</h1>
        <p class="gray-text">{{ $t("faqTab.troubleshooting.error401.receive") }}</p>
        <p class="gray-text">{{ $t("faqTab.troubleshooting.error401.cache") }}</p>
        <p class="gray-text mb-4">
            {{ $t("faqTab.troubleshooting.error401.makeSure") }}
            <a class='link-text' href='/' target='_blank'>{{ $t("faqTab.troubleshooting.error401.client") }}</a>
        </p>
        <p class="gray-text">
            {{ $t("faqTab.troubleshooting.error401.problem") }}
            <a class='link-text' :href='`mailto:${supportLink}`' target='_blank'>{{ supportLink }}</a>
        </p>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    computed: {
        ...mapGetters(['brandConfig']),
        supportLink() {
            return this.brandConfig.email
        }
    }
};
</script>

<style scoped>
h1,
p,
a {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.link-text {
    color: #918FFA;
    font-size: 14px;
}

.gray-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}
</style>
