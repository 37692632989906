<template>
    <div>
        <p class="answer">{{ $t("faqTab.watching.devicesAmount.changeYourPassword") }}</p>
        <a class="link-text" :href="link" target="_blank">{{ link }}</a>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    computed: {
        ...mapGetters(['brandConfig']),
        link() {
            return `${this.brandConfig.portalLink}/ChangePassword`
        }
    }
}
</script>

<style scoped>
a,
p {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}
.link-text {
    color: #918FFA;
    font-size: 14px;
}
.answer {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}
</style>