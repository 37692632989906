<template>
    <div>
        <h1 class="header">{{ $t("faqTab.account.headers.refund") }}</h1>
        <NoteComponent customClass="mb-4">
            <p>{{ $t("faqTab.account.refund.note") }}</p>
        </NoteComponent>
        <p class="gray-text">{{ $t("faqTab.account.refund.before") }}</p>
        <p class="gray-text mb-4">{{ $t("faqTab.account.refund.customer") }}</p>
        <p class="gray-text"> {{ $t("faqTab.account.refund.contact") }}</p>
    </div>
</template>

<script>
export default {
    components: {
        NoteComponent: () => import("@/components/NoteComponent.vue")
    },
};
</script>

<style scoped>
h1,
p {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.gray-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}
</style>
