<template>
    <div>
        <h1 class="header">{{ $t("faqTab.gettingStarted.headers.pay") }}</h1>
        <p class="gray-text mb-5">{{ $t("faqTab.gettingStarted.pay.weAllow") }}</p>
        <p class="gray-text mb-1">{{ $t("faqTab.gettingStarted.pay.follow") }}</p>
        <ul class="list-style-none">
            <li v-for="(item, index) in list" :key="item" class="gray-text">
                {{ item }}
                <a v-if="index === 0" :href="siteLink" target="_blank" class="link-text">{{ siteLink }}</a>
            </li>
        </ul>
        <NoteComponent>
            <p>{{ $t("faqTab.gettingStarted.pay.note") }}</p>
        </NoteComponent>
        <p class="gray-text mt-4">{{ $t("faqTab.gettingStarted.pay.cashAppIs") }}</p>
        <p class="gray-text mb-5">{{ $t("faqTab.gettingStarted.pay.pleaseSeeSteps") }}</p>
        <StepWrapper v-for="step in steps" :step="step" :key="step.id" />
        <p class="gray-text mb-2">
            {{ $t("faqTab.gettingStarted.pay.afterThat") }}
            <a class="link-text" :href='`mailto:${supportLink}`'>{{ supportLink }}</a>
            {{ $t("faqTab.gettingStarted.pay.helpYou") }}
        </p>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    components: {
        NoteComponent: () => import("@/components/NoteComponent.vue"),
        StepWrapper: () => import("../components/StepWrapper.vue")
    },
    computed: {
        ...mapGetters(['brandConfig']),
        supportLink() {
            return this.brandConfig.email
        },
        siteLink() {
            return this.brandConfig.portalLink
        }
    },
    data() {
        const list = this.$t("faqTab.gettingStarted.pay.list").split(";")

        return {
            list,
            steps: [
                {
                    id: 1,
                    title: 'faqTab.gettingStarted.pay.steps.step1',
                    imgName: 'step1',
                    link: 'pricingLink'
                },
                {
                    id: 2,
                    imgName: 'step2',
                    title: 'faqTab.gettingStarted.pay.steps.step2',
                },
                {
                    id: 3,
                    imgName: 'step3',
                    title: 'faqTab.gettingStarted.pay.steps.step3',
                },
                {
                    id: 4,
                    imgName: 'step4',
                    title: 'faqTab.gettingStarted.pay.steps.step4'
                },
                {
                    id: 5,
                    imgName: 'step5',
                    title: 'faqTab.gettingStarted.pay.steps.step5'
                },
                {
                    id: 6,
                    imgName: 'step6',
                    title: 'faqTab.gettingStarted.pay.steps.step6'
                },
                {
                    id: 7,
                    imgName: 'step7',
                    title: 'faqTab.gettingStarted.pay.steps.step7'
                },
                {
                    id: 8,
                    imgName: 'step8',
                    title: 'faqTab.gettingStarted.pay.steps.step8'
                },
                {
                    id: 9,
                    imgName: 'step9',
                    title: 'faqTab.gettingStarted.pay.steps.step9'
                },
                {
                    id: 10,
                    imgName: 'step10',
                    title: 'faqTab.gettingStarted.pay.steps.step10'
                },
                {
                    id: 11,
                    imgName: 'step11',
                    title: 'faqTab.gettingStarted.pay.steps.step11'
                },
                {
                    id: 12,
                    imgName: 'step12',
                    title: 'faqTab.gettingStarted.pay.steps.step12'
                },
                {
                    id: 13,
                    imgName: 'step13',
                    title: 'faqTab.gettingStarted.pay.steps.step13'
                }
            ]
        }
    },
};
</script>

<style scoped>
h1,
p,
button,
input,
label,
span,
li {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.gray-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}

.list-style-none {
    list-style-type: none;
    padding: 0;
}

.link-text {
    color: #918FFA;
}
</style>
