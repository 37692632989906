<template>
    <div>
        <h1 class="header">{{ $t("faqTab.troubleshooting.headers.general") }}</h1>
        <p class="gray-text mb-4">{{ $t("faqTab.troubleshooting.general.experiencing") }}</p>
        <ul class="list-number">
            <li class="mb-2">
                {{ $t("faqTab.troubleshooting.general.device") }}
            </li>
            <p class="gray-text mb-2">{{ $t("faqTab.troubleshooting.general.maintenance") }}</p>
            <p class="mb-1">{{ $t("faqTab.troubleshooting.general.cache") }}</p>
            <ul class="list-dics mb-4">
                <li v-for="item in list1" :key="item" class="gray-text">{{ item }}</li>
            </ul>
            <p class="mb-4">{{ $t("faqTab.troubleshooting.general.other") }}</p>
            <p class="mb-4">{{ $t("faqTab.troubleshooting.general.ifUsing") }}</p>
            <li class="mb-2">{{ $t("faqTab.troubleshooting.general.androidDevices") }}</li>
            <p class="gray-text mb-4">{{ $t("faqTab.troubleshooting.general.goTo") }}</p>
            <p class="gray-text mb-4">{{ $t("faqTab.troubleshooting.general.change") }}</p>
            <p class="gray-text mb-4">{{ $t("faqTab.troubleshooting.general.canHelp") }}</p>
            <p class="gray-text mb-4">{{ $t("faqTab.troubleshooting.general.devicesRun") }}</p>
            <p class="gray-text mb-4">{{ $t("faqTab.troubleshooting.general.usuallyThis") }}</p>
            <p class="gray-text mb-4">{{ $t("faqTab.troubleshooting.general.tryToPlay") }}</p>
            <li class="mb-2">{{ $t("faqTab.troubleshooting.general.reset") }}</li>
            <p class="gray-text mb-4">{{ $t("faqTab.troubleshooting.general.leave") }}</p>

            <li class="mb-2">{{ $t("faqTab.troubleshooting.general.makeSure") }}</li>
            <p class="gray-text mb-4">
                {{ $t("faqTab.troubleshooting.general.visit") }}
                <a :href="appsLink" target="_blank" class="link-text">{{ appsLink }}</a>
                {{ $t("faqTab.troubleshooting.general.then") }}
            </p>

            <li class="mb-2">{{ $t("faqTab.troubleshooting.general.runSpeedtest") }}</li>
            <p class="mb-1">{{ $t("faqTab.troubleshooting.general.guide") }}</p>
            <ul class="list-number mb-2">
                <li v-for="item in list2" :key="item" class="gray-text">
                    {{ item }}
                </li>
            </ul>
            <p class="mb-2">{{ $t("faqTab.troubleshooting.general.speedtestLink") }}</p>
            <a :href="speedLink" target="_blank" class="link-text">{{ speedLink }}</a>
            <p class="my-2">{{ $t("faqTab.troubleshooting.general.automatically") }}</p>
            <p class="gray-text mb-4">{{ $t("faqTab.troubleshooting.general.afterSpeedtest") }}</p>

            <li class="mb-4">{{ $t("faqTab.troubleshooting.general.seeOurVpn") }}</li>
            <li>{{ $t("faqTab.troubleshooting.general.problemPersists") }}
                <a class='link-text' :href='`mailto:${supportLink}`' target='_blank'>{{ supportLink }}</a>
            </li>
        </ul>
        <NoteComponent>
            <p class="gray-text">{{ $t("faqTab.troubleshooting.general.note") }}</p>
        </NoteComponent>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    components: {
        NoteComponent: () => import('@/components/NoteComponent.vue')
    },
    computed: {
        ...mapGetters(['brandConfig']),
        appsLink() {
            return `${this.brandConfig.portalLink}/Apps`
        },
        supportLink() {
            return this.brandConfig.email
        }
    },
    data() {
        const list1 = this.$t("faqTab.troubleshooting.general.list1").split(";")
        const list2 = this.$t("faqTab.troubleshooting.general.list1").split(";")

        return {
            speedLink: "http://speed.apg.ooo",
            list1,
            list2
        }
    }
};
</script>

<style scoped>
h1,
p,
li,
a {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.link-text {
    color: #918FFA;
    font-size: 14px;
}

.gray-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}

.list-number {
    list-style: decimal;
}

.list-dics {
    list-style: disc;
}
</style>
