<template>
    <div>
        <h1 class="header">{{ $t("faqTab.watching.headers.subtitles") }}</h1>
        <p class="gray-text">{{ $t("faqTab.watching.subtitles.request") }}</p>
        <ul class="list-container-number">
            <li class="gray-text" v-for="item in list" :key="item">
                {{ item }}
            </li>
        </ul>
        <p class="gray-text">
            {{ $t("faqTab.watching.subtitles.information") }}
            <a class="link-text" @click="handleChatInteraction">{{ $t("faqTab.watching.subtitles.openChat") }}</a>
            {{ $t("faqTab.watching.subtitles.members") }}
        </p>
    </div>
</template>

<script>
export default {
    data() {
        const list = this.$t("faqTab.watching.subtitles.list").split(";")
        return {
            list
        }
    },
    methods: {
        handleChatInteraction() {
            const sendpulseContainer = document.getElementsByTagName('sp-live-chat')[0];
            if (sendpulseContainer && sendpulseContainer.shadowRoot) {
                const sendpulseButton = sendpulseContainer.shadowRoot.querySelector(".widget-fab");
                if (sendpulseButton) {
                    sendpulseButton.click();
                }
            }
        }
    }
};
</script>

<style scoped>
h1,
p,
li {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.gray-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.link-text {
    color: #918FFA;
    font-size: 14px;
}

.list-container-number {
    list-style-type: decimal;
}
</style>
