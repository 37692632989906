<template>
    <div>
        <p class="answer mb-5">{{ $t('faqTab.gettingStarted.questions.recomendVpnA1') }}</p>
        <p class="answer mb-0">{{ $t('faqTab.gettingStarted.questions.recomendVpnA2') }}</p>
        <ul>
            <li class="answer" v-for="item in countryList" :key="item">
                {{ item }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        const countryList = this.$t("faqTab.gettingStarted.questions.countires").split(";")
        return {
            countryList
        }
    }
}
</script>

<style scoped>
li,
p {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

ul {
    list-style-type: none;
}

.answer {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}
</style>
