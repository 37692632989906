<template>
    <div>
        <h1 class="header">{{ $t("faqTab.account.headers.renewSubscriptions") }}</h1>
        <h1 class="white-title mb-6">{{ $t("faqTab.account.renewSubscriptions.useComputer") }}</h1>
        <p class="gray-text mb-6">{{ $t("faqTab.account.renewSubscriptions.wouldLike") }}</p>
        <h1 class="white-title">{{ $t("faqTab.account.renewSubscriptions.pleaseNote") }}</h1>
        <ul class="list-style">
            <li v-for="item in list" :key="item" class="gray-text">
                {{ item }}
            </li>
        </ul>
        <p class="gray-text mb-8">{{ $t("faqTab.account.renewSubscriptions.pleaseSee") }}</p>
        <StepWrapper v-for="step in steps" :step="step" :key="step.id" />
        <p class="gray-text mb-5">{{ $t("faqTab.account.renewSubscriptions.canEither") }}</p>

        <div v-for="item in guides" :key="item" class="mb-2">
            <p class="gray-text">{{ $t("faqTab.account.renewSubscriptions.using", { item }) }}</p>
            <a :href='portalLink + "/" + item' target="_blank" class="link-text">{{ `${portalLink}/${item}` }}</a>
        </div>

        <p class="gray-text mb-5">{{ $t("faqTab.account.renewSubscriptions.alwaysOpen") }}</p>
        <div class="img-container">
            <img class="img-step" src="../components/assets/step-images/step-6.png" alt="step-img"/>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
    components: {
        StepWrapper: () => import("../components/StepWrapper.vue")
    },
    computed: {
        ...mapGetters(['brandConfig']),
        portalLink() {
            return this.brandConfig.portalLink
        }
    },
    data() {
        const list = this.$t("faqTab.account.renewSubscriptions.noteList").split(";")

        return {
            list,
            guides: ['Moonpay', 'Coinsmart', 'VenmoCrypto', 'Bitbuy', 'ShakePay', 'CashApp'],
            steps: [
                {
                    id: 1,
                    title: 'faqTab.account.renewSubscriptions.goToWebsite',
                    imgName: 'step-1',
                },
                {
                    id: 2,
                    title: 'faqTab.account.renewSubscriptions.youWill',
                    imgName: 'step-2',
                },
                {
                    id: 3,
                    title: 'faqTab.account.renewSubscriptions.subscriptions',
                    imgName: 'step-3',
                },
                {
                    id: 4,
                    title: 'faqTab.account.renewSubscriptions.nextPage',
                    imgName: 'step-4',
                }
            ]
        }
    },
};
</script>

<style scoped>
h1,
p,
button,
input,
label,
span,
li {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.white-title {
    font-size: 15px;
    font-weight: 600;
}

.gray-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}

.list-style {
    list-style-type: circle;
    margin-bottom: 16px;
}

.link-text {
    color: #918FFA;
    font-size: 14px;
}
.img-container {
    display: flex;
    justify-content: center;
    background-color: #141417;
    border-radius: 8px;
    margin-top: 16px;
    padding-top: 20px;
}
@media only screen and (max-width: 870px) {
    .img-step {
        width: 100%;
    }
}
</style>
