<template>
    <div>
        <h1 class="header ">{{ $t("faqTab.watching.headers.request") }}</h1>
        <NoteComponent :customClass="'mb-4'">
            <p>{{ $t("faqTab.watching.request.acceptMovie") }}</p>
            <p>{{ $t("faqTab.watching.request.provideAdult") }}</p>
            <p>{{ $t("faqTab.watching.request.channelRequests") }}</p>
            <p>{{ $t("faqTab.watching.request.sportsVOD") }}</p>
        </NoteComponent>
        <NoteComponent :customClass="'mb-4'">
            <p>{{ $t("faqTab.watching.request.requestThat") }}</p>
        </NoteComponent>
        <p class="gray-text">
            {{ $t('faqTab.watching.request.onlyAccept') }}
            <a class='link-text' :href='`mailto:${supportLink}`' target='_blank'>{{ supportLink }}</a>
            {{ $t('faqTab.watching.request.onlyAccept2') }}
        </p>
        <p class="gray-text">{{ $t("faqTab.watching.request.requestMust") }}</p>
        <p class="mb-4 gray-text">{{ $t("faqTab.watching.request.searchFunction") }}</p>
        <NoteComponent :customClass="'mb-4'">
            <p>{{ $t("faqTab.watching.request.showRequests") }}</p>
        </NoteComponent>
        <h1 class="white-title mb-4">{{ $t("faqTab.watching.request.tvShowsRequests") }}</h1>
        <p class="gray-text">{{ $t("faqTab.watching.request.mustHold") }} <a class='link-text' :href="imdbLink" target='_blank'>{{
                imdbLink }}</a></p>
        <p class="mb-6 gray-text">{{ $t("faqTab.watching.request.showsThat") }}</p>
        <p class="gray-text">{{ $t("faqTab.watching.request.followSteps") }}</p>
        <p class="gray-text">{{ $t("faqTab.watching.request.visit") }} <a class='link-text' :href="imdbLink" target='_blank'>{{ imdbLink
                }}</a></p>
        <p class="gray-text">{{ $t("faqTab.watching.request.searchIMDB") }}</p>
        <p class="gray-text">{{ $t("faqTab.watching.request.hasRating") }}</p>
        <p class="gray-text">
            {{ $t("faqTab.watching.request.requestMeets") }}
            <a class='link-text' :href='`mailto:${supportLink}`' target='_blank'>{{ supportLink }}</a>
            {{ $t("faqTab.watching.request.include") }}
        </p>
        <p class="mb-3 gray-text">{{ $t("faqTab.watching.request.requestsSent") }}</p>

        <NoteComponent :customClass="'mb-4'">
            <p>{{ $t("faqTab.watching.request.regarding") }}</p>
        </NoteComponent>

        <p class="mb-4 gray-text">
            {{ $t("faqTab.watching.request.email") }}
            <a class='link-text' :href='`mailto:${supportLink}`' target='_blank'>{{ supportLink }}</a>
            {{ $t("faqTab.watching.request.onlyIf") }}
        </p>
        <h1 class="white-title mb-4">{{ $t("faqTab.watching.request.exampleRequest") }}</h1>
        <img src="@/components/assets/FAQ/rating.png" alt="rating" class="mb-4">
        <p class="mb-4 gray-text">{{ $t("faqTab.watching.request.numberOfVotes") }}</p>
        <h1 class="white-title mb-4">{{ $t("faqTab.watching.request.movieRequests") }}</h1>
        <p class="gray-text">{{ $t("faqTab.watching.request.notAccept") }}</p>
        <p class="mb-4 gray-text">{{ $t("faqTab.watching.request.moviesAreAdded") }}</p>
        <p class="gray-text">{{ $t("faqTab.watching.request.note") }}</p>
        <p class="gray-text">{{ $t("faqTab.watching.request.automaticallyAdded") }}</p>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    components: {
        NoteComponent: () => import("@/components/NoteComponent.vue")
    },
    computed: {
        ...mapGetters(['brandConfig']),
        supportLink() {
            return this.brandConfig.email
        }
    },
    data() {
        return {
            imdbLink: "https://www.imdb.com/"
        }
    }
};
</script>

<style scoped>
h1,
p,
button,
input,
label,
span,
li {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.gray-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.white-title {
    font-size: 15px;
    font-weight: 600;
}

.link-text {
    color: #918FFA;
}

</style>
