<template>
    <div>
        <h1 class="header">{{ $t("faqTab.troubleshooting.headers.badUsernameOrPassword") }}</h1>
        <ul class="list-container">
            <li v-for="item in list" :key="item" class="gray-text">
                {{ item }}
            </li>
            <li class="gray-text">
                4.{{ $t("faqTab.troubleshooting.badUsernameOrPassword.problem") }}
                <a class='link-text' :href='`mailto:${supportLink}`' target='_blank'>{{ supportLink }}</a>
            </li>
        </ul>
        <NoteComponent>
            <p>{{ $t("faqTab.troubleshooting.badUsernameOrPassword.sendUs") }}</p>
        </NoteComponent>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    components: {
        NoteComponent: () => import("@/components/NoteComponent.vue")
    },
    computed: {
        ...mapGetters(['brandConfig']),
        supportLink() {
            return this.brandConfig.email
        }
    },
    data() {
        const list = this.$t("faqTab.troubleshooting.badUsernameOrPassword.list").split(";")
        return {
            list
        }
    }
};
</script>

<style scoped>
h1,
p,
li,
a {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.header {
    font-size: 24px;
    font-weight: 700;
}

.link-text {
    color: #918FFA;
    font-size: 14px;
}

.list-container {
    list-style-type: decimal;
    margin: 16px 0;
}

.gray-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}
</style>
