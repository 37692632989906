<template>
    <div>
        <h1 class="header">{{ $t("faqTab.troubleshooting.headers.error404") }}</h1>
        <p class="gray-text mb-4">{{ $t("faqTab.troubleshooting.error404.error") }}</p>
        <p class="gray-text mb-6">{{ $t("faqTab.troubleshooting.error404.backup") }}</p>
        <p class="gray-text mb-6">{{ $t("faqTab.troubleshooting.error404.sometimes") }}</p>
        <p class="gray-text mb-4">{{ $t("faqTab.troubleshooting.error404.experienced") }}</p>
        <p class="gray-text mb-6">
            <a class='link-text' :href='`mailto:${supportLink}`' target='_blank'>{{ supportLink }}</a>
            {{ $t("faqTab.troubleshooting.error404.look") }}
        </p>
        <p class="gray-text">{{ $t("faqTab.troubleshooting.error404.provide") }}</p>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    computed: {
        ...mapGetters(['brandConfig']),
        supportLink() {
            return this.brandConfig.email
        }
    },
};
</script>

<style scoped>
h1,
p,
a {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.link-text {
    color: #918FFA;
    font-size: 14px;
}

.gray-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}
</style>
