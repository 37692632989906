<template>
    <div>
        <h1 class="header">{{ $t("faqTab.troubleshooting.headers.chromecast") }}</h1>
        <p class="gray-text mb-4">{{ $t("faqTab.troubleshooting.chromecast.variations") }}</p>
        <p class="gray-text">{{ $t("faqTab.troubleshooting.chromecast.before") }}</p>
        <NoteComponent :customClass="'my-4'">
            <p class="gray-text">{{ $t("faqTab.troubleshooting.chromecast.dontSupport") }}</p>
        </NoteComponent>
        <p class="gray-text mb-2">{{ $t("faqTab.troubleshooting.chromecast.why") }}</p>
        <ul class="list-disc">
            <li v-for="item in list1" :key="item" class="gray-text mb-2">
                {{ item }}
            </li>
        </ul>
        <p class="gray-text mt-4">{{ $t("faqTab.troubleshooting.chromecast.old") }}</p>
        <p class="gray-text my-2">{{ $t("faqTab.troubleshooting.chromecast.gen1") }}</p>
        <div class="img-container">
            <img class="image" src="../components/assets/devices/chromecast1.png"
                :alt="$t('faqTab.troubleshooting.chromecast.gen1')">
        </div>
        <p class="gray-text my-2">{{ $t("faqTab.troubleshooting.chromecast.gen2") }}</p>
        <div class="img-container">
            <img class="image" src="../components/assets/devices/chromecast2.png"
                :alt="$t('faqTab.troubleshooting.chromecast.gen2')">
        </div>
        <p class="gray-text my-2">{{ $t("faqTab.troubleshooting.chromecast.gen3") }}</p>
        <div class="img-container">
            <img class="image" src="../components/assets/devices/chromecast3.png"
                :alt="$t('faqTab.troubleshooting.chromecast.gen3')">
        </div>
        <p class="gray-text my-4">{{ $t("faqTab.troubleshooting.chromecast.new") }}</p>
        <p class="gray-text">{{ $t("faqTab.troubleshooting.chromecast.chromecastWith") }}</p>
        <div class="img-container">
            <img class="image" src="../components/assets/devices/chromecast4.png" alt="chromecast">
        </div>
        <div class="img-container">
            <img class="image" src="../components/assets/devices/chromecast5.png" alt="chromecast">
        </div>
        <p class="gray-text mt-4">{{ $t("faqTab.troubleshooting.chromecast.guide") }}</p>
        <ul class="list-number">
            <li v-for="item in list2" :key="item" class="gray-text mb-2">
                {{ item }}
            </li>
        </ul>
        <p class="gray-text my-4">{{ $t("faqTab.troubleshooting.chromecast.pleaseMake") }}</p>
        <p class="gray-text">{{ $t("faqTab.troubleshooting.chromecast.example") }}</p>
        <p class="link-text">{{ exampleLink }}</p>
    </div>
</template>

<script>
export default {
    components: {
        NoteComponent: () => import("@/components/NoteComponent.vue")
    },
    data() {
        const list1 = this.$t("faqTab.troubleshooting.chromecast.list1").split(";")
        const list2 = this.$t("faqTab.troubleshooting.chromecast.list2").split(";")

        return {
            list1,
            list2,
            exampleLink: "https://laon.live/api/list/YOURUSERNAME/PASSWORD"
        }
    }
}
</script>

<style scoped>
h1,
p,
li {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.image {
    max-width: 100%;
    border-radius: 8px;
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.img-container {
    display: flex;
    justify-content: center;
    background-color: #141417;
    border-radius: 8px;
    margin-top: 16px;
    padding-top: 20px;
}

.gray-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}

.link-text {
    color: #918FFA;
    font-size: 14px;
    word-break: break-word;
}

.list-disc {
    list-style-type: disc;
}

.list-number {
    list-style-type: decimal;
}
</style>
