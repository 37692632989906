<template>
    <div>
        <p v-for="item in list" :key="item" class="answer">{{ $t(item) }}</p>
    </div>
</template>

<script>
export default {
    data() {
        const list = ['faqTab.gettingStarted.questions.fastestServerA1', 'faqTab.gettingStarted.questions.fastestServerA2', 'faqTab.gettingStarted.questions.fastestServerA3', 'faqTab.gettingStarted.questions.fastestServerA4', 'faqTab.gettingStarted.questions.fastestServerA5']
        return {
            list
        }
    }
}
</script>

<style scoped>
h1,
p {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.answer {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}
</style>
