<template>
    <div>
        <h1 class="header">{{ $t("faqTab.troubleshooting.headers.error403") }}</h1>
        <p class="gray-text mb-4">{{ $t("faqTab.troubleshooting.error403.experiencing") }}</p>
        <p class="gray-text">{{ $t("faqTab.troubleshooting.error403.already") }}</p>
        <p class="gray-text">{{ $t("faqTab.troubleshooting.error403.provider") }}</p>
        <p class="gray-text">{{ $t("faqTab.troubleshooting.error403.switching") }}</p>
        <p class="gray-text mb-2">{{ $t("faqTab.troubleshooting.error403.several") }}</p>
        <p class="gray-text mb-2">{{ $t("faqTab.troubleshooting.error403.recommend") }}</p>
        <div class="vpn-container">
            <a v-for="item in vpnLinks" target="_blank" :key="item.text" :href="item.link" class="link-text">{{ item.text }}</a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            vpnLinks: [
                {
                    text: "NordVPN",
                    link: "https://nordvpn.com/"
                },
                {
                    text: "IPVanish",
                    link: "https://www.ipvanish.com/"
                },
                {
                    text: "Surfshark",
                    link: "https://surfshark.com/"
                },
            ],
        }
    }
};
</script>

<style scoped>
h1,
p,
a {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.link-text {
    color: #918FFA;
    font-size: 14px;
}
.gray-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}

.vpn-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-bottom: 16px;
}
</style>
