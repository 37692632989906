<template>
    <div>
        <h1 class="header">{{ $t("faqTab.watching.headers.vpn") }}</h1>

        <h1 class="white-title mb-4">{{ $t("faqTab.watching.vpn.mightNeed") }}</h1>

        <p class="gray-text">{{ $t("faqTab.watching.vpn.youAreFrom") }}</p>
        <p class="gray-text mb-4 ">{{ $t("faqTab.watching.vpn.noticed") }}</p>

        <p class="gray-text">{{ $t("faqTab.watching.vpn.advise") }}</p>
        <p class="gray-text mb-4">{{ $t("faqTab.watching.vpn.seeBlack") }}</p>

        <p class="gray-text">{{ $t("faqTab.watching.vpn.yourService") }}</p>
        <p class="gray-text">{{ $t("faqTab.watching.vpn.ourService") }}</p>
        <p class="gray-text mb-4">{{ $t("faqTab.watching.vpn.rsa") }}</p>

        <p class="gray-text mb-4">{{ $t("faqTab.watching.vpn.safe") }}</p>

        <p class="gray-text">{{ $t("faqTab.watching.vpn.throttling") }}</p>
        <p class="gray-text mb-4">{{ $t("faqTab.watching.vpn.avoidThat") }}</p>

        <p class="gray-text">{{ $t("faqTab.watching.vpn.improvement") }}</p>
        <p class="gray-text">
            {{ $t("faqTab.watching.vpn.run") }}
            <a class='link-text' :href='speedTestLink' target='_blank'>{{ speedTestLink }}</a>
            {{ $t("faqTab.watching.vpn.runTest") }}
        </p>
        <p class="gray-text">
            {{ $t("faqTab.watching.vpn.switching") }}
            <a class='link-text' :href='speedTestLink' target='_blank'>{{ speedTestLink }}</a>
            {{ $t("faqTab.watching.vpn.test") }}
        </p>
        <p class="gray-text mb-4">{{ $t("faqTab.watching.vpn.usingVpn") }}</p>

        <p class="gray-text mb-4">{{ $t("faqTab.watching.vpn.recommended") }}</p>
        <div class="vpn-container">
            <a v-for="item in vpnLinks" target="_blank" :key="item.text" :href="item.link" class="link-text">{{
                item.text }}</a>
        </div>

        <NoteComponent :customClass="'mb-4'">
            <p>{{ $t("faqTab.watching.vpn.dontUse") }}</p>
        </NoteComponent>

        <p class="gray-text">{{ $t("faqTab.watching.vpn.suggested") }}</p>

        <ul class="list-container">
            <li v-for="item in list" :key="item" class="gray-text">
                {{ item }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    components: {
        NoteComponent: () => import("@/components/NoteComponent.vue")
    },
    data() {
        const list = this.$t("faqTab.watching.vpn.list").split(";")
        return {
            speedTestLink: "http://speed.apg.ooo ",
            vpnLinks: [
                {
                    text: "NordVPN",
                    link: "https://nordvpn.com/"
                },
                {
                    text: "IPVanish",
                    link: "https://www.ipvanish.com/"
                },
                {
                    text: "Surfshark",
                    link: "https://surfshark.com/"
                },
            ],
            list
        }
    }
};
</script>

<style scoped>
h1,
p,
button,
input,
label,
span,
li {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.gray-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.white-title {
    font-size: 15px;
    font-weight: 600;
}

.link-text {
    color: #918FFA;
}

.list-container {
    list-style-type: none;
    padding: 0;
}

.vpn-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-bottom: 16px;
}
</style>
