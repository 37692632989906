<template>
    <div>
        <h1 class="header">{{ $t("faqTab.account.headers.password") }}</h1>
        <p class="gray-text mb-4">{{ $t("faqTab.account.password.option") }}</p>
        <StepWrapper v-for="step in firstStepsArray" :step="step" :key="step.id" />
        <p class="gray-text mb-4">{{ $t("faqTab.account.password.alsoChange") }}</p>
        <StepWrapper v-for="step in secondStepsArray" :step="step" :key="step.id" />
    </div>
</template>

<script>
export default {
    components: {
        StepWrapper: () => import("../components/StepWrapper.vue")
    },
    data() {
        return {
            firstStepsArray: [
                {
                    id: 1,
                    title: 'faqTab.account.password.click',
                    imgName: 'resend-1'
                },
                {
                    id: 2,
                    imgName: 'resend-2',
                    title: 'faqTab.account.password.entertheEmail',
                },
                {
                    id: 3,
                    imgName: 'resend-3',
                    title: 'faqTab.account.password.entertheEmail',
                    content: 'faqTab.account.password.receive'
                }
            ],
            secondStepsArray: [
                {
                    id: 4,
                    title: 'faqTab.account.password.link',
                    link: "changePasswordLink",
                },
                {
                    id: 5,
                    imgName: 'resend-4',
                    title: 'faqTab.account.password.usingYour',
                },
                {
                    id: 6,
                    imgName: 'resend-5',
                    title: 'faqTab.account.password.clickOn'
                },
                {
                    id: 7,
                    imgName: 'resend-6',
                    title: 'faqTab.account.password.newPassword',
                    content: 'faqTab.account.password.recommend'
                }
            ]
        }
    },
};
</script>

<style scoped>
h1,
p,
button,
input,
label,
span,
li {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.gray-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}

.list-style-none {
    list-style-type: none;
    padding: 0;
}

.link-text {
    color: #918FFA;
}
</style>
