<template>
    <div>
        <h1 class="header">{{ $t("faqTab.gettingStarted.headers.devices") }}</h1>
        <p class="gray-text mb-4">{{ $t("faqTab.gettingStarted.devices.weRecommend") }}</p>
        <NoteComponent>
            <p>{{ $t("faqTab.gettingStarted.devices.ifYourDevice") }}</p>
        </NoteComponent>
        <ul class="mt-4 border-padding list-container">
            <li v-for="item in firstList" :key="item">
                {{ item }}
            </li>
        </ul>
        <h1 class="white-title mb-4">{{ $t("faqTab.gettingStarted.devices.youCanWatch") }}</h1>
        <p class="gray-text pb-2">{{ $t("faqTab.gettingStarted.devices.example") }}</p>
        <p class="gray-text pb-2">{{ $t("faqTab.gettingStarted.devices.example1") }}</p>
        <p class="gray-text pb-2">{{ $t("faqTab.gettingStarted.devices.example2") }}</p>
        <p class="gray-text border-padding">{{ $t("faqTab.gettingStarted.devices.example3") }}</p>
        <h1 class="white-title mb-4 mt-4">{{ $t("faqTab.gettingStarted.devices.youCanWatchOnly") }}</h1>
        <p class="gray-text pb-1">{{ $t("faqTab.gettingStarted.devices.exampleFor") }}</p>
        <p class="gray-text pb-2">{{ $t("faqTab.gettingStarted.devices.exampleForUSA") }}</p>
        <NoteComponent>
            <ul class="list-style-none">
                <li v-for="item in secondList" :key="item">
                    {{ item }}
                </li>
            </ul>
        </NoteComponent>
    </div>
</template>

<script>
export default {
    components: {
        NoteComponent: () => import("@/components/NoteComponent.vue")
    },
    data() {
        const firstList = this.$t("faqTab.gettingStarted.devices.fireProducts").split(";")
        const secondList = this.$t("faqTab.gettingStarted.devices.noGeographical").split(";")
        return {
            firstList,
            secondList
        }
    }
};
</script>

<style scoped>
h1,
p,
button,
input,
label,
span,
li {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.white-title {
    font-size: 15px;
    font-weight: 600;
}

.gray-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}

.border-padding {
    padding-bottom: 16px;
    border-bottom: 1px solid #FFFFFF14;
}

.list-style-none {
    list-style-type: none;
    padding: 0;
}
.list-container {
    word-break: break-all;
}
</style>
