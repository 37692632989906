<template>
    <div>
        <h1 class="header">{{ $t("faqTab.gettingStarted.headers.badwidth") }}</h1>
        <p class="gray-text mb-4">{{ $t("faqTab.gettingStarted.badwidth.ourService") }}</p>
        <NoteComponent>
            <p>{{ $t("faqTab.gettingStarted.badwidth.pleaseUseOnly") }}</p>
        </NoteComponent>
        <p class="gray-text mb-5 mt-4">{{ $t("faqTab.gettingStarted.badwidth.ourSpeedtest") }}</p>
        <p class="gray-text mb-5">{{ $t("faqTab.gettingStarted.badwidth.example") }}</p>
        <p class="gray-text mb-2">{{ $t("faqTab.gettingStarted.badwidth.link") }}</p>
        <div class="py-3 px-4 copy-link">
            <span class="link-text">
                {{ speedLink }}
            </span>
            <div @click="copyToClipboard(speedLink)" class="copy-icon">
                <img src="@/components/assets/copy.svg" alt="copy" width="16">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {
        NoteComponent: () => import("@/components/NoteComponent.vue")
    },
    data() {
        return {
            speedLink: "http://speed.apg.ooo/"
        }
    },
    methods: {
        copyToClipboard(value) {
            navigator.clipboard.writeText(value)
        },
    },
};
</script>

<style scoped>
h1,
p,
button,
input,
label,
span,
li {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.gray-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}

.copy-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF1F;
    border-radius: 8px;
    border: 1px solid #FFFFFF1A;
}

.link-text {
    color: #918FFA;
}

.copy-icon {
    padding: 5px 8px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.12);
    cursor: pointer;
}
</style>
