<template>
    <div>
        <h1 class="mb-2">{{ $t("faqTab.gettingStarted.headers.pay") }}</h1>
        <p class="mb-4">{{ $t("faqTab.gettingStarted.subtexts.paypal") }}</p>
        <p class="mb-4" v-html="$t('faqTab.gettingStarted.paypal.currently', { 'website-link': brandConfig.portalLink })" />
        <StepWrapper v-for="step in steps" :step="step" :key="step.id" />

    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    components: {
        StepWrapper: () => import("../components/StepWrapper.vue")
    },
    computed: {
        ...mapGetters(['brandConfig']),
    },
    data() {

        return {
            paypal: "https/paypal",
            steps: [
                {
                    id: 0,
                    title: 'faqTab.gettingStarted.paypal.personal',
                    componentContent: () => import("../steps/Step0.vue")
                },
                {
                    id: 1,
                    title: 'faqTab.gettingStarted.paypal.login',
                    componentContent: () => import("../steps/Step1.vue")
                },
                {
                    id: 2,
                    title: 'faqTab.gettingStarted.paypal.buying',
                    componentContent: () => import("../steps/Step2.vue")
                },
                {
                    id: 3,
                    title: 'faqTab.gettingStarted.paypal.buyingBitcoin',
                    componentContent: () => import("../steps/Step3.vue")
                },
                {
                    id: 4,
                    title: 'faqTab.gettingStarted.paypal.sending',
                    componentContent: () => import("../steps/Step4.vue")
                },
                {
                    id: 5,
                    title: 'faqTab.gettingStarted.paypal.loginInto',
                    componentContent: () => import("../steps/Step6.vue")
                },
                {
                    id: 6,
                    title: 'faqTab.gettingStarted.paypal.getWallet',
                    componentContent: () => import("../steps/Step7.vue")
                },
                {
                    id: 6.1,
                    title: 'faqTab.gettingStarted.paypal.walletAdress',
                    componentContent: () => import("../steps/Step7.1.vue")
                },
            ]
        }
    }
};
</script>
