<template>
        <ul class="list-container mb-0">
            <li class="answer" v-for="item in translatedArray" :key="item">
                {{ item }}
            </li>
        </ul>
</template>

<script>
export default {
    data() {
        const translatedArray = this.$t("faqTab.watching.devicesAmount.notLimitList").split(";")
        return {
            translatedArray
        }
    }
}
</script>

<style scoped>
li,
p {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.answer {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}

.list-container {
    list-style-type: none;
    padding: 0;
}
</style>