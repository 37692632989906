<template>
    <div>
        <h1 class="header">{{ $t("faqTab.troubleshooting.headers.notMovies") }}</h1>
        <p class="gray-text">{{ $t("faqTab.troubleshooting.notMovies.working") }}</p>
        <ul class="list-container">
            <li v-for="item in list" :key="item" class="gray-text">
                {{ item }}
            </li>
        </ul>
        <p class="gray-text">{{ $t("faqTab.troubleshooting.notMovies.problemPersists") }}</p>
    </div>
</template>

<script>
export default {
    data() {
        const list = this.$t("faqTab.troubleshooting.notMovies.list").split(";")
        return {
            list
        }
    }
};
</script>

<style scoped>
h1,
p,
li {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.list-container {
    list-style-type: decimal;
    margin: 12px 0;
}

.gray-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}
</style>
