<template>
    <div>
        <h1 class="header">{{ $t("faqTab.gettingStarted.headers.questions") }}</h1>
        <div class="use-vpn">
            <div class="vpn-icon">
                <img src="@/components/assets/FAQ/vpn.svg" alt="vpn-icon">
            </div>

            <h1 class="vpn-title">{{ $t("faqTab.gettingStarted.questions.pleaseUseVpn") }}</h1>
            <p class="sub-title mb-5">{{ $t("faqTab.gettingStarted.questions.internetService") }}</p>
            <p class="sub-title">{{ $t("faqTab.gettingStarted.questions.pleaseRead") }}</p>
        </div>
        <DropDown v-for="item in questionsArray" :key="item.id" :question="$t(item.question)"
            :content="item.content">
        </DropDown>
    </div>
</template>

<script>
import Answer1 from '../components/Answers/Answer1.vue';
import Answer2 from '../components/Answers/Answer2.vue';
import Answer3 from '../components/Answers/Answer3.vue';
import Answer4 from '../components/Answers/Answer4.vue';
import Answer5 from '../components/Answers/Answer5.vue';
import Answer6 from '../components/Answers/Answer6.vue';
import Answer7 from '../components/Answers/Answer7.vue';
import Answer8 from '../components/Answers/Answer8.vue';
import Answer9 from '../components/Answers/Answer9.vue';
import Answer10 from '../components/Answers/Answer10.vue';

export default {
    components: {
        DropDown: () => import("../components/DropDown.vue")
    },
    data() {
        return {
            questionsArray: [
                {
                    id: "1",
                    question: "faqTab.gettingStarted.questions.whatIsVpnQ",
                    content: Answer1
                },
                {
                    id: "2",
                    question: "faqTab.gettingStarted.questions.internetProviderQ",
                    content: Answer2
                },
                {
                    id: "3",
                    question: "faqTab.gettingStarted.questions.accountsWorkFineQ",
                    content: Answer3
                },
                {
                    id: "4",
                    question: "faqTab.gettingStarted.questions.recommendQ",
                    content: Answer4
                },
                {
                    id: "5",
                    question: "faqTab.gettingStarted.questions.installVpnQ",
                    content: Answer5
                },
                {
                    id: "6",
                    question: "faqTab.gettingStarted.questions.alreadyHaveVpnQ",
                    content: Answer6
                },
                {
                    id: "7",
                    question: "faqTab.gettingStarted.questions.alreadyUsingVpnQ",
                    content: Answer7
                },
                {
                    id: "8",
                    question: "faqTab.gettingStarted.questions.fastestServerQ",
                    content: Answer8
                },
                {
                    id: "9",
                    question: "faqTab.gettingStarted.questions.recomendVpnQ",
                    content: Answer9
                },
                {
                    id: "10",
                    question: "faqTab.gettingStarted.questions.serviceWorksQ",
                    content: Answer10
                },
            ]
        }
    }
};
</script>

<style scoped>
h1,
p,
button,
input,
label,
span,
li {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.vpn-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
}

.sub-title {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
}

.vpn-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 40px;
    height: 40px;
    border-radius: 12px;
    background-color: #F4106B14;
    margin-bottom: 16px;
}

.use-vpn {
    padding: 24px;
    background-color: #11101099;
    border-radius: 12px;
    margin-bottom: 16px;
}
</style>
