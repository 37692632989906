<template>
    <div>
        <h1 class="header">{{ $t("faqTab.troubleshooting.headers.notWorking") }}</h1>
        <p class="gray-text mb-2">{{ $t("faqTab.troubleshooting.notWorking.experience") }}</p>
        <p class="gray-text mb-2">{{ $t("faqTab.troubleshooting.notWorking.smooth") }}</p>
        <ul class="mb-4">
            <li v-for="item in list1" :key="item" class="gray-text">
                {{ item }}
            </li>
        </ul>
        <h1 class="gray-text mb-2">{{ $t("faqTab.troubleshooting.notWorking.runningSpeedTest") }}</h1>
        <p class="gray-text mb-2">{{ $t("faqTab.troubleshooting.notWorking.shouldOpen") }}</p>
        <p class="gray-text mb-2">{{ $t("faqTab.troubleshooting.notWorking.speedTest") }}</p>
        <a :href="speedLink" target="_blank" class="link-text">{{ speedLink }}</a>
        <img class="speedtest-img my-4" src="../components/assets/speedtest.png" alt="speedtest">
        <p class="gray-text mb-2">{{ $t("faqTab.troubleshooting.notWorking.divided") }}</p>
        <ul class="list-number">
            <li>{{ $t("faqTab.troubleshooting.notWorking.firstSection") }}</li>
            <li class="mb-2">{{ $t("faqTab.troubleshooting.notWorking.secondSection") }}</li>
            <ul class="list-dics mb-2">
                <li v-for="item in list2" :key="item" class="gray-text">
                    {{ item }}
                </li>
            </ul>
            <p class="gray-text mb-2">{{ $t("faqTab.troubleshooting.notWorking.testing") }}</p>

            <li class="mb-2">{{ $t("faqTab.troubleshooting.notWorking.thirdSection") }}</li>
            <p class="gray-text mb-2">{{ $t("faqTab.troubleshooting.notWorking.limited") }}</p>
            <p class="gray-text mb-2">{{ $t("faqTab.troubleshooting.notWorking.beAware") }}</p>
            <NoteComponent customClass="my-4">
                <p class="gray-text ">{{ $t("faqTab.troubleshooting.notWorking.note") }}</p>
            </NoteComponent>
            <p class="gray-text mb-2">{{ $t("faqTab.troubleshooting.notWorking.useVpn") }}</p>
            <p class="gray-text ">{{ $t("faqTab.troubleshooting.notWorking.providers") }}</p>
        </ul>
    </div>
</template>

<script>
export default {
    components: {
        NoteComponent: () => import('@/components/NoteComponent.vue')
    },
    data() {
        const list1 = this.$t("faqTab.troubleshooting.notWorking.list1").split(";")
        const list2 = this.$t("faqTab.troubleshooting.notWorking.list2").split(";")

        return {
            list1,
            list2,
            speedLink: "https://speed.apg.ooo"
        }
    }
};
</script>

<style scoped>
h1,
p,
li {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.list-container {
    list-style-type: decimal;
    margin: 12px 0;
}

.gray-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}

.speedtest-img {
    width: 100%;
    border-radius: 8px;
}

.link-text {
    color: #918FFA;
}
.list-number {
    list-style: decimal;
}
.list-dics {
    list-style: disc;
}
</style>
