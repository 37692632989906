<template>
    <div class="main-faq">
        <div class="faq-general-container">
            <div class="faq">
                <Header />
                <Wrapper>
                    <div v-if="content">
                        <div class="navigation-container">
                            <button @click="setContent(null)" class="back-button">
                                <img src="@/components/assets/FAQ/back-arrow.svg" alt="arrow" class="mr-2">
                                {{ $t("faqTab.back") }}
                            </button>
                            <p class="gray-text">
                                FAQ / {{ activeTitle }} / <span class="text-color-white">{{ itemTitle }}</span>
                            </p>
                        </div>
                        <component :is="content" />
                    </div>
                    <div v-else>
                        <TabButtons :faqArray="faqArray" :changeTab="changeTab" :activeTitle="activeTitle" />

                        <div class="mt-6 mb-4">
                            <h3 class="mb-2">{{ activeTitle }}</h3>
                            <p>{{ $t('faqTab.gettingStarted.subTitle') }}</p>
                        </div>

                        <div class="d-flex justify-space-between flex-wrap tab-container">
                            <div v-for="item in contentArray" :key="item.id" class="tab-item"
                                @click="setContent(item.content, $t(item.title))">
                                <h3 class="tab-item-title">{{ $t(item.title) }}</h3>
                                <p class="tab-item-update-date">{{ item.updateDate }}</p>
                                <p class="tab-item-subtext">{{ $t(item.subtext) }}</p>
                            </div>
                        </div>
                    </div>
                </Wrapper>
            </div>
        </div>
        <Envelope />
    </div>
</template>

<script>
import { faqData } from './faqData';

export default {
    components: {
        Wrapper: () => import('@/components/Wrapper.vue'),
        Header: () => import('@/components/SideNavPc.vue'),
        Envelope: () => import('@/components/Envelope.vue'),
        TabButtons: () => import('./components/TabButtons.vue')
    },
    data() {
        return {
            faqArray: faqData,
            contentArray: [],
            activeTitle: '',
            itemTitle: '',
            content: null
        }
    },
    computed: {},
    methods: {
        changeTab(title, content) {
            this.activeTitle = title
            this.contentArray = content
        },
        setContent(content, title) {
            this.content = content
            this.itemTitle = title
        }
    },
    mounted() {
        this.changeTab(this.$t(this.faqArray[0].title), this.faqArray[0].content)
    }
};
</script>

<style scoped>
h3,
p,
button,
input,
label,
span {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.main-faq {
    display: flex;
    justify-content: space-between;
}

.faq {
    max-width: 1103px;
}

.tab-item {
    width: 49%;
    padding: 24px;
    border-radius: 8px;
    background-color: #141417;
    margin-bottom: 8px;
    cursor: pointer;
    transition: .2s all;
}

.tab-item:hover {
    background-color: #14141794;
}

.tab-item-title {
    font-size: 16px;
    color: #918FFA;
    text-decoration: underline;
    margin-bottom: 4px;
}

.tab-item-subtext {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
}

.tab-item-update-date {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.3);
    margin-bottom: 4px;
}

.navigation-container {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.back-button {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: center;
}

.gray-text {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}

@media only screen and (max-width: 1500px) {
    .faq {
        max-width: 980px;
    }
}

@media only screen and (max-width: 1390px) {
    .faq {
        max-width: 100%;
    }

    .main-faq {
        flex-direction: column;
        align-items: center;
    }

    .faq-general-container {
        width: 100%;
    }
}

@media only screen and (max-width: 580px) {
    .tab-item {
        width: 100%;
    }

    .navigation-container {
        flex-direction: column;
        align-items: flex-start;
    }
}
</style>
