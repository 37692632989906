<template>
    <div>
        <p class="answer">{{ $t("faqTab.watching.devicesAmount.visitFAQ") }}</p>
        <a class="link-text" :href="link" target="_blank">{{ link }}</a>
        
        <p class="answer mt-6">{{ $t("faqTab.watching.devicesAmount.notes") }}</p>
        <ul class="list-container">
            <li class="answer" v-for="item in notesList" :key="item">
                {{ item }}
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    computed: {
        ...mapGetters(['brandConfig']),
        link() {
            return `${this.brandConfig.portalLink}/Apps`
        }
    },
    data() {
        const notesList = this.$t("faqTab.watching.devicesAmount.notesList").split(";")

        return {
            notesList
        }
    }
}
</script>

<style scoped>
a,
p,
li {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}
.list-container {
    list-style-type: none;
    padding: 0;
}
.link-text {
    color: #918FFA;
    font-size: 14px;
}
.answer {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}
</style>