<template>
    <div>
        <h1 class="header">{{ $t("faqTab.account.headers.accountStatus") }}</h1>
        <p class="gray-text mb-4">
            {{ $t("faqTab.account.accountStatus.toAccess") }}
            <a class='link-text' :href='siteLink' target='_blank'>{{ siteLink }}</a>
            {{ $t("faqTab.account.accountStatus.subscriptionTab") }}
        </p>
        <p class="gray-text mb-4">{{ $t("faqTab.account.accountStatus.alsoSee") }}</p>
        <p class="gray-text mb-4">{{ $t("faqTab.account.accountStatus.note") }}</p>
        <p class="gray-text">
            {{ $t("faqTab.account.accountStatus.detect") }}
            <a class='link-text' :href='`mailto:${email}`' target='_blank'>{{ email }}</a>
        </p>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    computed: {
        ...mapGetters(['brandConfig']),
        siteLink() {
            return this.brandConfig.portalLink
        },
        email() {
            return `billing@${window.location.host}`
        }
    }
};
</script>

<style scoped>
h1,
p,
button,
input,
label,
span,
li {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.gray-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}

.link-text {
    color: #918FFA;
    font-size: 14px;
}
</style>
