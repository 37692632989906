<template>
    <div>
        <h1 class="header">{{ $t("faqTab.troubleshooting.headers.playerType") }}</h1>
        <p>{{ $t("faqTab.troubleshooting.playerType.switching") }}</p>
        <NoteComponent customClass="my-4">
            <p>{{ $t("faqTab.troubleshooting.playerType.help") }}</p>
        </NoteComponent>
        <p class="gray-text mb-4">{{ $t("faqTab.troubleshooting.playerType.devices") }}</p>
        <p class="gray-text mb-4">{{ $t("faqTab.troubleshooting.playerType.experiences") }}</p>
        <ul class="list-number">
            <li v-for="item in list" :key="item" class="gray-text mb-2">
                {{ item }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    components: {
        NoteComponent: () => import("@/components/NoteComponent.vue"),
    },
    data() {
        const list = this.$t("faqTab.troubleshooting.playerType.list").split(";")

        return {
            list,
        }
    },
};
</script>

<style scoped>
h1,
p,
li {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.gray-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}

.list-number {
    list-style: decimal;
}
</style>
